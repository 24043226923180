import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'
import { LocationContext } from '../pages';
import appStore from '../images/appstore.png'
import playStore from '../images/playstore.png'
import { display, space } from 'styled-system';
import { isAndroid, isIOS } from "react-device-detect";
import { AngledCTAButton } from './AngledButton';


const StoreButton = styled.img`
  max-width: 138px;
  transition: .66s all;
  position: relative;
  border-radius: 5px;
  ${display};
  ${space};
  &:hover {
    transition: .3s all;
    transform: scale(1.0666);
  }
  @media screen and (min-width: 342px) {
    margin-right: ${props => props.index !== 0 ? 0 : ds.spacing.s3};
  }
`
const trackerParams = {
  hitType: "event",
  eventAction: "button click",
}

export const handleAnalytics = (location, storeType) => {
  if (!window.ga) return;
  if (!location.search) return window.ga("send", {
    ...trackerParams,
    eventCategory: storeType,
    eventLabel: location.pathname + location.search,
  });
  return window.ga("send", {
    ...trackerParams,
    eventCategory: storeType,
    eventLabel: location.pathname + location.search,
  })
}

export const conditionalButtonDisplay = () => {
  if (isIOS || isAndroid) return { device: "Mobile" };
  else return {
    device: "Desktop",
    data: [
      { link: "https://itunes.apple.com/ca/app/tonit/id1321053644", location: "App Store", buttonSource: appStore },
      { link: "https://play.google.com/store/apps/details?id=com.tonitmedia.tonit", location: "Play Store", buttonSource: playStore }
    ],
  };
};

export const detectDeviceLink = () => {
  if (isIOS) return { link: "https://itunes.apple.com/ca/app/tonit/id1321053644", location: "App Store" };
  else if (isAndroid) return { link: "https://play.google.com/store/apps/details?id=com.tonitmedia.tonit", location: "Play Store" };
  else return [
    { link: "https://itunes.apple.com/ca/app/tonit/id1321053644", location: "App Store", buttonSource: appStore },
    { link: "https://play.google.com/store/apps/details?id=com.tonitmedia.tonit", location: "Play Store", buttonSource: playStore }
  ];
};

export const handleButtonTap = async (location) => {
  const href = detectDeviceLink();
  await handleAnalytics(location, href.location);
  return window.open(href.link);
};

export const AppStoreButtons = props => {
  const href = conditionalButtonDisplay();

  return <LocationContext.Consumer>
    {
      location => {
        return <div>
          {href.device === "Desktop"
            ? href.data.map((b, i) => {
              return <a
                key={`${i}`}
                href={b.link}
                onClick={() => handleAnalytics(location, b.location)}
                rel='noopener noreferrer'
                target='_blank'
                style={{ textDecoration: "none" }}
                name={`Download now at the ${b.location}`}
              >
                <StoreButton key={`${i}${b.location}`} index={i} src={b.buttonSource} alt={`Download now from the ${b.location}`} style={{ maxWidth: "100px", boxShadow: "1px 1px 3px #606060" }} />
              </a>
            })
            : <div
              onClick={() => handleButtonTap(location)}
              rel='noopener noreferrer'
              target='_blank'
              style={{ textDecoration: "none" }}
              name={`Download now at the app store`}
            >
              <AngledCTAButton label={props.label} bg={props.bg} focus={props.focus} color={props.color} />
            </div>
          }
        </div>
      }
    }
  </LocationContext.Consumer>
}