import React from 'react'
import styled from 'styled-components'
import { Headline, H2 } from '../uiLibrary/Typographyv2'
import { GatsbyHeroImage } from './heroes'
import * as ds from '../uiLibrary'

const SvgTop = ({ fill }) => (
  <svg viewBox="0 0 1440 140" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0L752.5 71L0 140V0Z" fill={fill} />
    <path d="M753 71L0 0H1440L753 71Z" fill="black" />
  </svg>
)

const SvgBottom = () => (
  <svg viewBox="0 0 1430 138" xmlns="http://www.w3.org/2000/svg">
    <path d="M1430 138H0L1430 0V138Z" fill="black" />
  </svg>
)

export const Banner = ({ url, headline, accent }) => {
  return (
    <Wrap>
      <Top>
        <SvgTop fill={accent || ds.brandColors.mediumBlue} />
      </Top>

      <GatsbyHeroImage url={url} />

      <BottomWrap>
        <Bottom>
          <SvgBottom />
        </Bottom>
        {headline ? (
          <HeadlineWrap>
            {/* <TitleWrap style={{ backgroundImage: `url(${url.src})` }}> */}
            <Title
              textAlign={'center'}
              fontSize={[
                ds.typeScale.t7,
                ds.typeScale.t7,
                ds.typeScale.t8,
                '7rem',
              ]}
              color={ds.brandColors.typography.white}
            >
              {headline}
            </Title>
            {/* </TitleWrap> */}
          </HeadlineWrap>
        ) : null}
      </BottomWrap>
    </Wrap>
  )
}

const SvgWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;

  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
  }
`
// TODO tried to do some stuff with background and knockout text but the text becomes hard to read depending on the image
const TitleWrap = styled.div`
  background-size: 100% auto;
  background-position: bottom center;
`

const Title = styled(Headline)`
  color: white;
  background-color: #000;
`

const Top = styled(SvgWrap)`
  top: 0;
`

const Bottom = styled(SvgWrap)`
  position: relative;
`

const BottomWrap = styled.div`
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: 0;
`

const HeadlineWrap = styled.div`
  position: relative;
  z-index: 200;

  h1 {
    position: relative;
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: ${ds.mq.medium}) {
    h1 {
    }
  }
  @media screen and (min-width: ${ds.mq.large}) {
    h1 {
    }
  }
`

const Wrap = styled.div`
  position: relative;
  overflow: hidden;
`
