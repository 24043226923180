import React from 'react'
import { space, maxWidth, maxHeight, display } from 'styled-system'
import styled from 'styled-components'
import { Link } from 'gatsby'
import tonitLogo from '../images/tonit-logo-wm.png'
import tonitLogo2x from '../images/tonit-logo-wm@2x.png'
import tonitIcon from '../images/tonit-icon.png'
import tonitIcon2x from '../images/tonit-icon@2x.png'
import tonitBadgeIcon from "../../static/images/Tonit-Menu-Icon.png"

export const StyledLogo = styled.img`
  max-width: 150px;
  ${space};
  ${maxWidth};
  ${maxHeight};
  ${display};
`
export const FooterLogo = props => (
  <StyledLogo
    src={tonitIcon}
    srcSet={`${tonitIcon} 1x, ${tonitIcon2x} 2x`}
    maxHeight="1.66em"
    alt="Tonit home page"
    style={{ opacity: 0.5 }}
  />
)

export const NavBarLogo = props => (
  <Link to="/" name="TONIT Logo to Homepage">
    <StyledLogo
      {...props}
      src={tonitBadgeIcon}
      srcSet={`${tonitLogo} 1x, ${tonitLogo2x} 2x`}
      display={"block"}
      pt={[2, 0]}
      maxWidth={["75px", "100px", "150px"]}
      name="TONIT Logo Navigate Home"
      alt="Tonit Motorcycles - The social app for motorcyclists"
    />
  </Link>
)
