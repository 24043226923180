import React from 'react';
import * as ds from '../uiLibrary';
import styled from 'styled-components';
import { fontSize, color } from 'styled-system'
import { PCta, PLarge } from "../uiLibrary/Typographyv2";

const MaterialIcon = styled.span`
font-family: 'Material Icons';
color: ${ds.brandColors.darkGreen};
${color};
${fontSize};
`

const Value = props => (
  <ds.StyledDiv index={props.index} width={['100%', '100%', '20%']} borderLeft={['none', 'none', `1px solid`]} bc={props.iconColor}>
    <MaterialIcon color={props.iconColor} fontSize={[ds.typeScale.t7, ds.typeScale.t8]}>{props.icon}</MaterialIcon>
    <PCta fontSize={ds.typeScale.t4} fontWeight="800" color={"white"}>{props.title}</PCta>
    <PLarge fontWeight={"200"} color={"white"}>
      {props.value}
    </PLarge>
  </ds.StyledDiv>
);

const ChampionValues = ({ values, ...props }) => (
  <ds.CenteredColumn bg={ds.brandColors.bg.dark} flexDirection={['column', 'column', 'row']} style={{}}>
    {values.map((v, i) => (
      <Value {...v} key={i} index={i} iconColor={props.iconColor} />
    ))}
  </ds.CenteredColumn>
)

export default ChampionValues