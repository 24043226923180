import React from "react";
import styled from "styled-components";
import { display } from "styled-system";
import * as ds from "../uiLibrary";
import { H3, PLarge } from "../uiLibrary/Typographyv2";
import DashLines from "../../static/images/DashLinesBlack.png";
import { isMobile } from "react-device-detect";

const GoldBoxStyle = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${ds.brandColors.darkGold};
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  @media screen and (min-width: ${ ds.mq.medium}) {;
    width: 200px;
    height: 200px;
    justify-content: start;
    ${display};
  }
  @media screen and (min-width: ${ ds.mq.large}) {
    width: 250px;
    height: 250px;
    ${display};
  }
  &:hover {
    transition: .3s all;
    transform: scale(1.0333);
  }
`

const AbsoluteSlash = styled.img`
  position: relative;
  top: -27%;
  right: -20%;
  height: 80px;
  ${display};
  @media screen and (min-width: ${ ds.mq.medium}) {;
    top: -27%;
    right: -20%;
    ${display};
  }
  @media screen and (min-width: ${ ds.mq.large}) {
    top: -27%;
    right: -20%;
    ${display};
  }
`
const handleTap = () => {
  if (!isMobile) return;
  else return window.open(`https://tonitmedia.app.link/fullthrottlesubscription`);
};

export const GoldBox = props => {
  return <div
    style={{ textDecoration: "none" }}
    onClick={() => handleTap()}
    target="_blank"
    rel="noopener noreferrer"
  >
    <GoldBoxStyle>
      <AbsoluteSlash alt={"TONIT logo slash lines"} src={DashLines} display={["none", "none", "block"]} />
      <H3 my={0} fontSize={[ds.typeScale.t6, ds.typeScale.t6, ds.typeScale.t7]}>{props.price}</H3>
      <H3 my={0} fontSize={[ds.typeScale.t6, ds.typeScale.t6, ds.typeScale.t7]}>{props.duration}</H3>
      {
        props.note
          ? <PLarge>{props.note}</PLarge>
          : null
      }
    </GoldBoxStyle>
  </div>
}