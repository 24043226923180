import React, { useState } from 'react'
import Axios from 'axios'
import { StyledInput, OrangeText, GreenText, P } from '../uiLibrary'
import * as ds from '../uiLibrary'
import { AngledSubmitButton } from '../components'
import { setConfig } from 'react-hot-loader'
import { withStyleFunctions } from '../utils'

setConfig({ pureSFC: true })

const Subscribe = props => {
  const [email, setEmail] = useState('')
  const [hp, setHp] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const subscribeData = props.data

  const handleSubmit = async e => {
    e.preventDefault()

    // stop execution if honeypot has a value
    if (hp) return

    try {
      await Axios({
        method: 'put',
        url: `${process.env.TONIT_API_URL}/newsletter/subscribe/${email}`,
      })
      setSuccess(subscribeData.success)
    } catch (e) {
      setError(subscribeData.error)
    }
  }

  const handleInputChange = value => {
    setSuccess('')
    setError('')
    return setEmail(value)
  }

  return (
    <form {...props} onSubmit={handleSubmit}>
      <div>
        <OrangeText>{error}</OrangeText>
        <GreenText>{success}</GreenText>
      </div>

      <P fontSize={3} mb={0}>
        {props.body}
      </P>
      <div style={{ display: 'flex' }}>
        <label
          id="lbl-email-input"
          htmlFor="emailInput"
          style={{ display: 'none' }}
        >
          {subscribeData.placeholder}
        </label>
        <StyledInput
          id="emailInput"
          aria-labelledby="lbl-email-input"
          onChange={e => handleInputChange(e.target.value)}
          placeholder={subscribeData.placeholder}
          placeholderColor={ds.brandColors.typography.darkGray}
          value={email}
          label={subscribeData.placeholder}
          type="email"
          required
        />
        <AngledSubmitButton
          bg={'#5A663D'}
          focus={'#516623'}
          ml={'-0.5em'}
          label={subscribeData.submitLabel}
          type="submit"
        />
      </div>

      {/* honeypot */}
      <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
        <input
          onChange={e => setHp(e.target.value)}
          tabIndex="-1"
          type="text"
          value={hp}
        />
      </div>

      {/* <StyledSubmit
      type="submit"
      value="Count me in"
      name="subscribe"
    /> */}
    </form>
  )
}

export const SubscribeForm = withStyleFunctions(Subscribe)
