import React from "react";
import styled from "styled-components";
import { space, display, width } from "styled-system";
import Img from "gatsby-image";

const StyledFeaturedImage = styled(Img)`
  width: 300px;
  ${space};
  ${display};
  ${width};
`

export const GatsbyFeaturedImage = props => <StyledFeaturedImage fixed={props.url} alt={props.alt}/>;