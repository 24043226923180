import React from 'react';
import { AngledButton, AppStoreButtons } from "../components";
import { GatsbySectionHero } from "../components/heroes";
import styled from 'styled-components';
import { space, display, minHeight } from "styled-system";
import * as ds from "../uiLibrary";
import DashLines from "../../static/images/DashLines.png";
import { H3, PSmall } from "../uiLibrary/Typographyv2";

const BlackBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  background-color: black;
  width: 100%;
  min-height: 300px;
  ${minHeight};
  max-height: 325px;
  ${space};
  @media screen and (min-width: ${ds.mq.medium}) {
    width: 300px;
    justify-self: center;
  }
  @media screen and (min-width: ${ds.mq.large}) {
    justify-self: right;
  }
`

const AbsoluteSlash = styled.img`
  position: absolute;
  top: 28%;
  right: 25%;
  height: 75px;
  ${display};
  @media screen and (min-width: ${ ds.mq.medium}) {;
    top: 28%;
    right: ${props => props.flip ? "66%" : "17%"};
    ${display};
  }
  @media screen and (min-width: ${ ds.mq.large}) {
    top: 32%;
    right: ${props => props.flip ? "63%" : "21%"};
    ${display};
  }
`
/**
 *  Gatsby Image Section - renders optimized gatsby image background with a black info box and dash lines
 * @param {string} containerColor color of the section background
 * @param {string} url background image
 * @param {string} urlDescription alt tag for the background image
 * @param {boolean} flip black box is on the right hand side by default, flip to true to place on left
 * @param {boolean} reverse is true when black box has an inner image with the title above it
 * @param {boolean} button true if the box has a button
 * @param {string} buttonLabel label on the button
 * @param {string} buttonLink relative url of the button, defaults to '/'
 * @param {string} buttonColor the background color of the button, defaults to brand green
 * @param {string} focus the hover color of the button, defaults to dark brand green
 * @param {string} src the source image for the inner black box image
 * @param {string} srcDescription the alt tag for the inner box image
 * @param {string} titleColor color of the title in the black box, defaults to grey
 * @param {string} title title in the black box
 * @param {string} firstBody text contained in the first of three optional paragraphs
 * @param {string} secondBody text in the second paragraph
 * @param {string} thirdBody text in the last paragraph
 */
export const GatsbyImageSection = ({
  containerColor,
  url,
  urlDescription,
  flip,
  reverse,
  button,
  box,
  buttonLabel,
  buttonLink,
  buttonColor,
  focus,
  src,
  srcDescription,
  titleColor,
  title,
  firstBody,
  secondBody,
  thirdBody,
  storeButton,
}) => {

  return <ds.FixedWidthContainer bg={containerColor} pt={["2em", "3em", "5em"]}>
    <GatsbySectionHero fluid={url} alt={urlDescription} style={{ paddingTop: "10rem" }} height={box ? "auto" : "600px"}>
      <ds.FixedWidthRow>
        <ds.Column span={6} order={flip ? 2 : 1}>
          <div />
        </ds.Column>
        <ds.Column span={6} display="flex" justifyContent="center" order={flip ? 1 : 2}>
          {
            box ?
              <BlackBox justifyContent={"right"} minHeight={["auto", "300px"]}>
                <AbsoluteSlash src={DashLines} flip={flip} display={["none", "none", "block"]} alt={"TONIT Slash lines"} />
                {
                  reverse && title && src
                    ? <><H3 mb={0} mt={[ds.spacing.s1, ds.spacing.s3]} mx={ds.spacing.s2} color={titleColor ? titleColor : "#BEBEBE"} style={{ textTransform: "uppercase" }}>{title}</H3>
                      <ds.CenteredImage mw="250px" py={ds.spacing.s3} src={src} alt={srcDescription} />
                    </>
                    : null
                }
                {
                  !reverse && src
                    ? <ds.CenteredImage mw="250px" py={ds.spacing.s3} src={src} alt={srcDescription} />
                    : null
                }
                {
                  !reverse && title
                    ? <H3 my={ds.spacing.s3} mx={ds.spacing.s2} color={titleColor ? titleColor : "#BEBEBE"} style={{ textTransform: "uppercase" }}>{title}</H3>
                    : null
                }
                {
                  firstBody
                    ? <PSmall fontSize={[ds.typeScale.t3, ds.typeScale.t4]} mx={ds.spacing.s2} my={0} color="white">{firstBody}</PSmall>
                    : null
                }
                {
                  secondBody
                    ? <PSmall fontSize={[ds.typeScale.t3, ds.typeScale.t4]} mx={ds.spacing.s2} my={ds.spacing.s2} color="white">{secondBody}</PSmall>
                    : null
                }
                {
                  thirdBody
                    ? <PSmall fontSize={[ds.typeScale.t3, ds.typeScale.t4]} mx={ds.spacing.s2} my={0} color="white">{thirdBody}</PSmall>
                    : null
                }
                {
                  button
                    ? <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                      {
                        storeButton
                          ? <AppStoreButtons bg={buttonColor} focus={focus} label={buttonLabel || "Download Now"} />
                          : <AngledButton bg={buttonColor} focus={focus} label={buttonLabel || `TONIT`} href={buttonLink} />
                      }
                    </div>
                    : null
                }
              </BlackBox>
              : null
          }
        </ds.Column>
      </ds.FixedWidthRow>
    </GatsbySectionHero>
  </ds.FixedWidthContainer >
};