import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'
import { NavBarLogo } from '.'
import { NavBarSocialIcons, Drawer } from '../components'
import { display, space } from 'styled-system'

const StyledNavBar = styled.div`
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 100%;
  ${space};
`
const FlexyFixedWidthRow = styled(ds.FixedWidthRow)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Links = styled.ul`
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  ${display};
`

const StyledLink = styled.a`
  color: ${ds.colors.softWhite};
  text-decoration: none;
  font-family: Roboto Condensed, ${ds.sansSerif};
  transition: color 0.3s;
  font-size: ${ds.typeScale.t4};
  display: inline;
  margin-left: ${ds.spacing.s4};
  &:hover {
    transition: color 0.2s;
    color: ${ds.colors.white};
  }
`

export const NavBar = ({ data, locale }) => {
  const header = data

  return (
    <StyledNavBar>
      <FlexyFixedWidthRow py={3}>
        <NavBarLogo />
        <Drawer display={['flex', 'none']} locale={locale} />
        <Links display={['none', 'flex']}>
          {header.menu.map((item, idx) => (
            <li key={`${item.url}${idx}`}>
              <StyledLink key={idx} href={item.url} name={`${item.title}`}>
                {item.title.toUpperCase()}
              </StyledLink>
            </li>
          ))}
          <li>
            <NavBarSocialIcons accounts={header.social} />
          </li>
        </Links>
      </FlexyFixedWidthRow>
    </StyledNavBar>
  )
}
