import React from 'react'
import { AppStoreButtons } from './'
import * as ds from '../uiLibrary'
import { H3, H4, PCta } from "../uiLibrary/Typographyv2";
import { GatsbyFeaturedImage } from './GatsbyImage';

export const Cta = ({ heading, subheading, introImage, imageDescription, body, screenshot, screenshotAlt }) => {
  return <ds.FixedWidthRow py={0}>
    <ds.Column vc span={6} order={[2]}>
      {
        introImage
          ? <ds.CenteredImg maxWidth="60%" display="flex" src={introImage} alt={imageDescription}/>
          : null
      }
      <H3
        margin="0 auto"
        pt={ds.spacing.s3}
        maxWidth="80%"
        textAlign={"center"}
        alignSelf={"center"}
        fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t6]}
        color={"#414141"}>
        {heading}
      </H3>
      <PCta
        fontSize={[ds.typeScale.t4, ds.typeScale.t5]}
        textAlign="center"
        alignSelf="center">
        {subheading}
      </PCta>
      {
        body
          ? <H4
            maxWidth={["80%"]}
            textAlign={"center"}
            alignSelf={"center"}
            fontSize={[ds.typeScale.t4, ds.typeScale.t4, ds.typeScale.t5]}
            color={"#414141"}>
            {body}
          </H4>
          : null}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1em", flexWrap: "wrap"}}>
        <AppStoreButtons label="Download Now" bg={ds.brandColors.darkGreen} focus={ds.brandColors.lightGreen}/>
      </div>
    </ds.Column>
    <ds.Column vc span={6} order={[1]} pt={[ds.spacing.s4, 0]} alignItems={"center"}>
      <GatsbyFeaturedImage url={screenshot} alt={screenshotAlt} />
    </ds.Column>
  </ds.FixedWidthRow>
}