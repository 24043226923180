import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { space } from "styled-system";

const StyledGatsbyHeroImage = styled(Img)`
  max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
  width: ${props => props.width ? props.width : `1440px`};
  ${space};
`
export const GatsbyHeroImage = props => <StyledGatsbyHeroImage fluid={props.url} />;

const StyledRestrictedImage = styled(Img)`
  max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
  width: ${props => props.width ? props.width : `400px`};
  ${space};
`

/**
 * Size restricted Gatsby image container for responsive image styles
 * @param {url} props
 */
export const GatsbyRestrictedImage = props => {
  return <StyledRestrictedImage fluid={props.url} />;
}
