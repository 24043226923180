import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { NavBar, Footer } from '../'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import * as theme from '../../uiLibrary/constants'
import styled from 'styled-components'
import { LanguageSwitcherDesktop } from '../LanguageSwitcher'

const Wrapper = styled.div`
  overflow-x: hidden;
  position: relative;
`

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${theme.brandColors.bg.dark}
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Helvetica Neue",
      Helvetica,
      "Segoe UI",
      Roboto,
      Overpass,
      Arial,
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol";
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }
`

export default ({ children, locale }) => {
  const localeFallback = locale || 'en'

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
          allSocialJson {
            edges {
              node {
                facebookUrl
                instagramUrl
                socialTitle
                socialDescription
                socialImage
              }
            }
          }
          allHeaderJson {
            edges {
              node {
                menu {
                  title
                  url
                }
              }
            }
          }
          allFooterJson {
            edges {
              node {
                newsletterBody
                supportLinks {
                  text
                  link
                  buttonLabel
                }
                subscribe {
                  placeholder
                  submitLabel
                  success
                  error
                }
                termLinks {
                  text
                  link
                }
              }
            }
          }
          allHeaderEsJson {
            edges {
              node {
                menu {
                  title
                  url
                }
              }
            }
          }
          allFooterEsJson {
            edges {
              node {
                newsletterBody
                supportLinks {
                  text
                  link
                  buttonLabel
                }
                subscribe {
                  placeholder
                  submitLabel
                  success
                  error
                }
                termLinks {
                  text
                  link
                }
              }
            }
          }
        }
      `}
      render={data => {
        const jsonLocale =
          localeFallback[0].toUpperCase() + localeFallback.slice(1)
        const headerJson = data[`allHeader${jsonLocale}Json`]
          ? data[`allHeader${jsonLocale}Json`]
          : data.allHeaderJson
        const footerJson = data[`allFooter${jsonLocale}Json`]
          ? data[`allFooter${jsonLocale}Json`]
          : data.allFooterJson
        const socialJson = data[`allSocial${jsonLocale}Json`]
          ? data[`allSocial${jsonLocale}Json`]
          : data.allSocialJson

        return (
          <>
            <GlobalStyle />
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'keywords',
                  content:
                    'social network for motorcyclists, social network for bikers, motorcycle community, motorcycle app, app for motorbikes, app for motorcyclists',
                },
                {
                  property: 'og:type',
                  content: 'website',
                },
                {
                  property: 'og:url',
                  content: 'http://www.tonit.com',
                },
                {
                  property: 'og:title',
                  content: data.site.socialTitle,
                },
                {
                  property: 'og:description',
                  content: data.site.socialDescription,
                },
                {
                  property: 'og:image',
                  content: `http://www.tonit.com${data.allSocialJson.socialImage}`,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
                {
                  name: 'twitter:title',
                  content: data.site.socialTitle,
                },
                {
                  name: 'twitter:description',
                  content: data.site.socialDescription,
                },
                {
                  name: 'twitter:image',
                  content: `http://www.tonit.com${data.allSocialJson.socialImage}`,
                },
                {
                  name: 'viewport',
                  content:
                    'content=width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
                },
              ]}
            >
              <script>
                {`window.ga = window.ga || function() { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date;
              ga('create', 'UA-114952878-1', 'auto');
              // specify which page they're landing on.
              ga('send', 'pageview', location.pathname + location.search);`}
              </script>
              {/* hotjar heatmap tracking script */}
              <script>
                {`(function(h,o,t,j,a,r){
              h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
              h._hjSettings={hjid: 1129097,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
              </script>

              <script>
                {`if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
                window.navigator.serviceWorker.getRegistrations().then(registrations => {
                  registrations.forEach(r => r.unregister())
                  console.log('service worker unregistered');
                })
              }`}
              </script>
              <script
                async
                src="https://www.google-analytics.com/analytics.js"
              ></script>
              <script
                defer
                async
                src="https://identity.netlify.com/v1/netlify-identity-widget.js"
              ></script>
              <link
                href="https://fonts.googleapis.com/css?family=Overpass:300,400,500,700,900|Roboto+Condensed|Roboto:300,400,500|Material+Icons|display=swap"
                rel="stylesheet"
              />
              <link
                href="https://unpkg.com/ionicons@4.5.5/dist/css/ionicons.min.css"
                rel="stylesheet"
              ></link>
              <link
                rel="stylesheet"
                href="https://use.typekit.net/vea8lof.css"
              ></link>
              <html lang={localeFallback} />
            </Helmet>
            <ThemeProvider theme={theme}>
              <Wrapper>
                <LanguageSwitcherDesktop locale={localeFallback} />
                <NavBar
                  data={{
                    ...headerJson.edges[0].node,
                    social: socialJson.edges[0].node,
                  }}
                  locale={localeFallback}
                />
                {children}
                <Footer
                  data={{
                    ...footerJson.edges[0].node,
                    social: socialJson.edges[0].node,
                  }}
                />
              </Wrapper>
            </ThemeProvider>
          </>
        )
      }}
    />
  )
}
