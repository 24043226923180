import React from "react";
import * as ds from "../uiLibrary";
import { H3, H4, LineItem, PLarge } from "../uiLibrary/Typographyv2";
import { TriangleDiv, AppStoreButtons, AngledButton } from "../components";
import { GatsbyRestrictedImage } from "./heroes";

export const InfoBulletContainerSection = ({
  title,
  titleColor,
  header,
  headerColor,
  body1,
  body2,
  body3,
  bodyColor,
  textAlign,
  bullets,
  image,
  alt,
  order,
  triangle,
  bg,
  pt,
  pb,
  index,
}) => {
  return <ds.FixedWidthContainer bg={bg ? bg : "white"} pt={index === 0 ? ds.spacing.s6 : pt} pb={pb}>
    {
      index !== 0 && triangle
        ? <TriangleDiv
          display={["none", "block", "block"]}
          bg={bg ? bg : "white"}
          mt={index !== 0 ? ds.spacing.s4 : 0}
        />
        : null
    }
    <ds.FixedWidthRow py={0}>
      <ds.Column span={12}>
        {
          title
            ? <H3
              mt={0}
              fontSize={[ds.typeScale.t5, ds.typeScale.t6, ds.typeScale.t7]}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf="center"
              style={{ textTransform: "uppercase" }}
              color={titleColor || ds.brandColors.typography.black}
            >
              {title}
            </H3>
            : null
        }
        {
          header
            ? <H4
              fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t6]}
              fontWeight="400"
              textAlign={textAlign ? textAlign : "center"}
              alignSelf="center"
              color={headerColor || ds.brandColors.typography.dark}>
              {header}
            </H4>
            : null
        }
      </ds.Column>
      <ds.Column vc span={image ? 6 : 12} order={order ? [2, 1] : [1, 2]}>
        {
          body1
            ? <H4
              fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t5]}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf="center"
              color={bodyColor || ds.brandColors.typography.dark}>
              {body1}
            </H4>
            : null
        }
        {
          body2
            ? <H4
              fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t5]}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf="center"
              color={bodyColor || ds.brandColors.typography.dark}>
              {body2}
            </H4>
            : null
        }
        {
          body3
            ? <H4
              fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t5]}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf="center"
              color={bodyColor || ds.brandColors.typography.dark}>
              {body3}
            </H4>
            : null
        }
        {
          bullets
            ? <ul style={{ display: "flex", flexDirection: "column" }}>
              {bullets.map((line, i) => {
                return <LineItem
                  key={i}
                  textAlign="left"
                  fontSize={[ds.typeScale.t4, ds.typeScale.t4, ds.typeScale.t5]}
                  color={bodyColor || ds.brandColors.typography.dark}
                >
                  {line}
                </LineItem>
              })} </ul>
            : null
        }
      </ds.Column>
      <ds.Column vc span={image ? 6 : 0} order={order ? [1, 2] : [2, 1]} alignItems={"center"}>
        {/* <GifVideo videoSrcUrl={eventsGif} videoTitle={alt} /> */}
        <ds.CenteredImage src={image} alt={alt} mw={"60%"}/>
      </ds.Column>
    </ds.FixedWidthRow>
  </ds.FixedWidthContainer>
}

export const InfoContainerSection = ({
  title,
  titleColor,
  header,
  headerColor,
  body1,
  body2,
  body3,
  bodyColor,
  textAlign,
  button,
  storeButton,
  buttonColor,
  buttonFocus,
  buttonLabel,
  buttonLink,
  image,
  imageExists,
  alt,
  order,
  triangle,
  bg,
  pb,
  pt,
  index,
}) => {
  return <ds.FixedWidthContainer
    bg={bg ? bg : "white"}
    pb={pb}
    pt={pt}
    alignItems="center"
  >
    {
      triangle
        ? <TriangleDiv
          display={["none", "none", "block"]}
          bg={bg ? bg : "white"}
          mt={index !== 0 ? ds.spacing.s4 : 0}
        />
        : null
    }
    <ds.FixedWidthRow py={0}>
      <ds.Column vc span={image ? 6 : 12} order={order ? 2 : [2, 2]} py={order ? [ds.spacing.s5, 0] : [ds.spacing.s3, 0]}>
        {
          header
            ? <PLarge
              fontSize={[ds.typeScale.t4, ds.typeScale.t4, ds.typeScale.t5]}
              fontWeight="400"
              textAlign={textAlign ? textAlign : "center"}
              color={headerColor || ds.brandColors.typography.dark}
            >
              {
                image
                  ? <>{header}</>
                  : <em>{header}</em>
              }
            </PLarge>
            : null
        }
        {
          title
            ? <H3
              mt={0}
              mb={ds.spacing.s3}
              fontSize={[ds.typeScale.t5, ds.typeScale.t6, ds.typeScale.t7]}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf="left"
              style={{ textTransform: "uppercase" }}
              color={titleColor || ds.brandColors.typography.black}
            >
              {title}
            </H3>
            : null
        }
        {
          body1
            ? <H4
              fontSize={image ? [ds.typeScale.t4] : [ds.typeScale.t4, ds.typeScale.t5]}
              my={ds.spacing.s2}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf={image ? "left" : "center"}
              maxWidth={image ? ["auto"] : ["80%"]}
              color={bodyColor || ds.brandColors.typography.dark}>
              {body1}
            </H4>
            : null
        }
        {
          body2
            ? <H4
              fontSize={image ? [ds.typeScale.t4] : [ds.typeScale.t4, ds.typeScale.t5]}
              my={ds.spacing.s2}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf={image ? "left" : "center"}
              maxWidth={image ? ["auto"] : ["80%"]}
              color={bodyColor || ds.brandColors.typography.dark}>
              {body2}
            </H4>
            : null
        }
        {
          body3
            ? <H4
              fontSize={image ? [ds.typeScale.t4] : [ds.typeScale.t4, ds.typeScale.t5]}
              my={ds.spacing.s2}
              textAlign={textAlign ? textAlign : "center"}
              alignSelf={image ? "left" : "center"}
              maxWidth={image ? ["auto"] : ["80%"]}
              color={bodyColor || ds.brandColors.typography.dark}>
              {body3}
            </H4>
            : null
        }
        {
          button
            ? <div style={{ display: "flex", justifyContent: textAlign, marginTop: "15px" }}>
              {
                storeButton
                  ? <AppStoreButtons bg={buttonColor} focus={buttonFocus} label={buttonLabel || "Download Now"} />
                  : <AngledButton bg={buttonColor} focus={buttonFocus} label={buttonLabel || `TONIT`} href={buttonLink} />
              }
            </div>
            : null
        }
      </ds.Column>
      {
        image && imageExists ?
          <ds.Column vc span={image ? 6 : 0} order={order ? 1 : [1, 2]} alignItems={"center"}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><GatsbyRestrictedImage url={image} alt={alt} /></div>
          </ds.Column>
          : null
      }
    </ds.FixedWidthRow>
  </ds.FixedWidthContainer>
}