const locales = ['en', 'es']
const DEFAULT_LOCALE = 'en'
/**
 *
 * @param path relative site url
 * @returns supported locale, fallback to en
 */
export const useLocale = path => {
  if (!path) return DEFAULT_LOCALE

  const parts = path.split('/')
  const baseSegment = parts[1] || ''

  return locales.includes(baseSegment) ? baseSegment : DEFAULT_LOCALE
}
