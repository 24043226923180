import styled from 'styled-components'
import {
	space,
	color,
	fontWeight,
	textAlign,
	fontSize,
	fontFamily,
	display,
	width,
	flex,
	alignSelf,
	lineHeight,
	letterSpacing,
	justifyContent,
	maxWidth,
} from 'styled-system'
import * as ds from '.'

/**
 * Main H1 at the top of each page in hero
 * Only used once per page
 */
export const Headline = styled.h1`
	text-transform: uppercase;
	margin-top: ${ds.spacing.s3};
  line-height: ${ds.lineHeight.lh3};
	font-family: interstate, ${ds.sansSerif};
	font-weight: 800;
	font-display: swap;
	color: ${ds.brandColors.typography.mediumGrey};
  ${fontSize};
  ${fontWeight};
  ${color};
  ${textAlign};
  ${fontFamily};
  ${display};
  ${space};
`

/**
 * H2 - Used to break up the main sections
 * Used for quotes and to add visual breaks
 */
export const H2 = styled.h2`
	text-transform: uppercase;
	font-size: ${ds.typeScale.t8};
	margin-top: ${ds.spacing.s3};
	margin-bottom: ${ds.spacing.s3};
	line-height: ${ds.lineHeight.lh3};
	font-family: interstate-condensed, ${ds.sansSerif};
	font-weight: 700;
	font-display: swap;
	${space};
	${color};
	${fontWeight};
	${textAlign};
	${fontSize};
	${fontFamily};
	${display};
	${width};
	${flex};
	${alignSelf};
	${lineHeight};
	${letterSpacing};
`

/**
 * H3 - Header
 * Used in the black accent boxes
 * And for white space headers in sections
 */
export const H3 = styled.h3`
	font-size: ${ds.typeScale.t6};
	line-height: ${ds.lineHeight.lh3};
	font-family: interstate-condensed, ${ds.sansSerif};
	font-weight: 700;
	font-display: swap;
	${space};
	${color};
	${fontWeight};
	${textAlign};
	${fontSize};
	${fontFamily};
	${display};
	${width};
	${flex};
	${maxWidth};
	${alignSelf};
	${letterSpacing};
	${justifyContent};
	${lineHeight};
`

/**
 * H4 - Extra smaller header if needed
 */
export const H4 = styled.h3`
	font-size: ${ds.typeScale.t4};
	margin-top: ${ds.spacing.s3};
	line-height: ${ds.lineHeight.lh4};
	font-family: Overpass, ${ds.sansSerif};
	font-weight: 400;
	font-display: swap;
	${space};
	${color};
	${fontWeight};
	${textAlign};
	${fontSize};
	${fontFamily};
	${display};
	${width};
	${flex};
	${maxWidth};
	${alignSelf};
	${lineHeight};
	${letterSpacing};
`

/**
 * P - large text
 * Additional page information and
 * Accent text
 */
export const PLarge = styled.p`
	font-size: ${ds.typeScale.t4};
	margin-top: ${ds.spacing.s3};
	line-height: ${ds.lineHeight.lh3};
	font-family: Overpass, ${ds.sansSerif};
	font-weight: 400;
	font-display: swap;
	${space};
	${color};
	${fontWeight};
	${textAlign};
	${fontSize};
	${fontFamily};
	${display};
	${width};
	${flex};
	${alignSelf};
	${lineHeight};
	${letterSpacing};
`

/**
 * P - small text
 * Black box informational text
 * And for use in carousels
 */
export const PSmall = styled.p`
	font-size: ${ds.typeScale.t4};
	line-height: ${ds.lineHeight.lh3};
	font-family: Overpass, ${ds.sansSerif};
	font-weight: 200;
	font-display: swap;
	${space};
	${color};
	${fontWeight};
	${textAlign};
	${fontSize};
	${fontFamily};
	${display};
	${width};
	${flex};
	${alignSelf};
	${lineHeight};
	${letterSpacing};
`

/**
 * PCta - Call to Action text
 * Used for "download the app" text
 * Condensed and all caps
 */
export const PCta = styled.p`
	text-transform: uppercase;
	font-family: Overpass, ${ds.sansSerif};
	font-weight: 400;
	font-size: ${ds.typeScale.t5};
	letter-spacing: 0.01em;
	color: black;
	font-display: swap;
	${space};
	${color};
	${fontWeight};
	${textAlign};
	${fontSize};
	${fontFamily};
	${display};
	${width};
	${flex};
	${alignSelf};
	${lineHeight};
	${letterSpacing};
`

export const LineItem = styled.li`
	font-size: ${ds.typeScale.t4};
	margin-top: ${ds.spacing.s3};
	line-height: ${ds.lineHeight.lh3};
	font-family: Overpass, ${ds.sansSerif};
	font-weight: 400;
	font-display: swap;
	${space};
	${color};
	${fontWeight};
	${textAlign};
	${fontSize};
	${fontFamily};
	${display};
	${width};
	${flex};
	${alignSelf};
	${lineHeight};
	${letterSpacing};
`
