import styled from "styled-components";
import { display, height, space, color } from "styled-system";

export const TriangleDiv = styled.div`
  background-color: white;
  transform: rotate(-4deg);
  height: 8em;
  position: relative;
  top: -8em;
  width: 99%;
  margin: 0 auto;
  ${display};
  ${space};
  ${color};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.5rem;
    width: 1.2em;
    background: inherit;
    transform: rotate(4deg);
    transform: skew(-4deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -0.5rem;
    width: 1.2em;
    background: inherit;
    transform: rotate(4deg);
    transform: skew(-4deg);
  }
`

export const AngledDivider = styled.div`
  background-color: #e0e0e0;
  transform: rotate(-4deg) skew(-4deg);
  position: relative;
  margin: 0 auto;
  ${height};
  ${display};
`