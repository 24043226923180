import React from 'react'
import * as ds from '../uiLibrary'
import styled from 'styled-components'
import { width } from 'styled-system'

const Wrapper = styled(ds.Div)`
  display: inline-block;
  ${width};
  height: auto;
  margin-right: 3px;
`

export const MediaKit = ({ videos, ...props }) => (
  <>
    {videos.map((video) => (
      <Wrapper key={video.title} width={["100%", "100%", "48%"]}>
        <iframe title={video.title} width="100%" style={{ minHeight: "281px" }} src={video.url} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Wrapper>
    ))}
  </>
)