import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'

const AnimatronicVerticulator = styled.div`
  padding: 1em 0;
  overflow: hidden;
  margin-bottom: 2em;
  transition: max-height .5s ease-in-out;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  max-height: ${ props => props.isOpen ? `2000px` : `56px` };
`
const FlexyMuhFuhr = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
`
export class JobPosting extends React.Component{
  state = {
    isOpen: false,
  }

  render() {
    const { isOpen } = this.state
    return(
      <AnimatronicVerticulator isOpen={isOpen}>
        <FlexyMuhFuhr onClick={ this._expandPosting.bind(this) }>
          <ds.H4 color={ds.colors.typography.darkPrimary}>{this.props.position}</ds.H4>
          <ds.H4 color={ds.colors.typography.darkPrimary}>▾</ds.H4>
        </FlexyMuhFuhr>
        { this.props.render() }
      </AnimatronicVerticulator>
    )
  }
  _expandPosting = e => {
    this.setState({ isOpen: !this.state.isOpen })
  }
}