import styled from "styled-components";
import { space, display, maxWidth, height } from "styled-system";
import BackgroundImage from "gatsby-background-image";

export const GatsbySectionHero = styled(BackgroundImage)`
  position: relative;
  background-color: #000;
  background: url(${props => props.url});
  background-size: cover;
  background-position: ${props => props.bp ? props.bp : "center"};
  z-index: 0;
  max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
  width: ${props => props.width ? props.width : `1440px`};
  ${space};
  ${maxWidth};
  ${display};
  ${height};
`