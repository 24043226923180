import styled from 'styled-components'
import { space, maxWidth } from 'styled-system'
import * as ds from '../uiLibrary'

export const FeatureImage = styled.img`
  max-width: ${props => props.maxWidth ? props.maxWidth : '50%'};
  transition: transform .3s;
  textAlign: ${props => props.textAlign ? props.textAlign : 'left'}
  width: ${ props => props.width ? props.width : `232px`};
  &:hover ${props => props.hover
    ? `{
    transition: transform .333s;
    transform: scale(1.1);
    }`
    : null};
  ${space};
  ${maxWidth};
  @media screen and (min-width: ${ ds.mq.medium}) {
  }
`
