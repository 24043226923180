import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  display: flex;
  width: 100%;
`


export const Video = ({ videoSrcUrl, videoTitle, ...props }) => {
  return <VideoContainer>
    <iframe
      title={videoTitle}
      width="1065"
      height="446"
      src={videoSrcUrl}
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen="true">
    </iframe>
  </VideoContainer>
}

export const GifVideo = ({ videoSrcUrl, videoTitle, ...props }) => {
  return <video
    autoplay="true"
    loop
    height="600"
    muted="true"
    playsinline
    alt={videoTitle}
  >
    <source src={videoSrcUrl} type="video/mp4" />
  </video>

}