import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'

const StyledInput = styled.input`
  color: ${ ds.colors.darkGray };
  width: 100%;
  padding: 0.5em 1em;
  font-size: 1em;
  margin-top: 0.5em;
  border: 1px solid gray;
  font-family: 'Work Sans';
  border-radius: 4px;
`
const StyledSubmit = styled.input`
  background: ${ds.colors.brandRed};
  border-radius: 2px;
  font-size: 0.9em;
  padding: 4px 9px;
  cursor: pointer;
  outline: none;
  border: none;
  color: white;
`

const androidSubscribeUrl="https://tonit.us17.list-manage.com/subscribe/post?u=d65d3040c5a843eca0ad667c8&amp;id=4bf515cc19"
const androidHoneypotName="b_d65d3040c5a843eca0ad667c8_4bf515cc19"
const iosSubscribeUrl="https://tonit.us17.list-manage.com/subscribe/post?u=d65d3040c5a843eca0ad667c8&amp;id=6d5e6dd576"
const iosHoneypotName="b_d65d3040c5a843eca0ad667c8_6d5e6dd576"

export const SubscribeFormLight = props =>
  <form action={
    props.android ?
    androidSubscribeUrl :
      props.ios ?
      iosSubscribeUrl :
    "https://tonit.us17.list-manage.com/subscribe/post?u=d65d3040c5a843eca0ad667c8&amp;id=a623c1797f"
  }
    method="post"
    id="mc-embedded-subscribe-form"
    name="mc-embedded-subscribe-form"
    target="_blank" noValidate>
      <div id="mc_embed_signup_scroll">
        <StyledInput type="email" name="EMAIL" id="mce-EMAIL" placeholder="email address" required />
        <div style={{position: 'absolute', left: -5000}} aria-hidden="true">
          <input type="text" tabIndex="-1" value="" name={
            props.android ?
            androidHoneypotName :
              props.ios ?
              iosHoneypotName :
              "b_d65d3040c5a843eca0ad667c8_a623c1797f"
            } />
        </div>
        <StyledSubmit type="submit" value="sign up" name="subscribe" id="mc-embedded-subscribe" className="mt3" />
      </div>
  </form>
