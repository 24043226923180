import React from "react";
import * as ds from "../uiLibrary";
import { H3, PLarge } from "../uiLibrary/Typographyv2";
import { GatsbyFeaturedImage } from "./GatsbyImage";


export const CenteredPictureDisplay = (props) => {
  return <ds.FixedWidthContainer bg={"white"}>
    <ds.FixedWidthRow py={[0, ds.spacing.s3]} display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexWrap="wrap">
      <ds.Column span={6} display="flex" justifyContent="center" flexDirection="row" alignItems="center">
        <GatsbyFeaturedImage url={props.image.fixed} alt={props.alt} />
      </ds.Column>
      <ds.Column span={6} px={ds.spacing.s5} textAlign="center">
        <H3 color={ds.brandColors.typography.dark}>{props.name}</H3>
        <PLarge color={ds.brandColors.typography.black}>{props.position}</PLarge>
      </ds.Column>
    </ds.FixedWidthRow>
  </ds.FixedWidthContainer >

}