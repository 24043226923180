import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layouts'
import {
  GatsbyImageSection,
  GatsbyQuoteSection,
  Cta,
  TriangleDiv,
  InfoContainerSection,
  AppStoreButtons,
  AngledButton,
} from '../components'
import HeadMeta from '../components/HeadMeta'
import { GatsbyHeroImage } from '../components/heroes'
import * as ds from '../uiLibrary'
import { Headline, H2, PCta } from '../uiLibrary/Typographyv2'
import QuoteSlider from '../components/QuoteSlider'
import { isBrowser } from 'react-device-detect'
import { useLocale } from '../hooks/useLocale'
import { Banner } from '../components/Banner'

export const LocationContext = React.createContext()

export const Homepage = ({ data, ...props }) => {
  const page = data.allHomeJson.edges[0].node
  const CTAscreenshot = data.ctaScreenshot.edges[0]
  const bannerImage = data.banner.childImageSharp
  const imageSectionData = data.sections.edges.map(({ node }, i) => {
    return { image: node.childImageSharp, ...page.imageSections[i] }
  })
  const quoteSectionData = data.quotes.edges.map(({ node }, i) => {
    return { image: node.childImageSharp, ...page.quoteSections[i] }
  })
  const testimonialSectionData = data.testimonials.edges.map(({ node }, i) => {
    return { image: node.childImageSharp, ...page.testimonials[i] }
  })
  const screenshots = data.screenshots.edges.reduce((acc, curr, i) => {
    acc[`shot${i}`] = curr
    return acc
  }, {})

  const currentLocale = useLocale(props.location.pathname)

  // v2 banner doesn't have embed text in image
  const BannerImage = page.bannerHeadline ? Banner : GatsbyHeroImage

  return (
    <LocationContext.Provider value={props.location}>
      <Layout locale={currentLocale}>
        <HeadMeta
          pageTitle={page.pageTitle || 'Tonit'}
          pageDescription={page.pageDescription}
          socialTitle={page.socialTitle}
          socialDescription={page.socialDescription}
          socialImage={page.socialImage}
        />
        <ds.Background background={'#000000'}>
          <ds.FixedWidthContainer
            py={[ds.spacing.s5, ds.spacing.s6, ds.spacing.s6]}
          >
            <BannerImage
              url={bannerImage.fluid}
              headline={page.bannerHeadline}
              accent={ds.brandColors.mediumGreen}
            />
            <Headline
              textAlign={'center'}
              color={ds.brandColors.mediumGreen}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t6, ds.typeScale.t7, ds.typeScale.t8]}
            >
              {page.heroHeading}
            </Headline>
            <H2
              color={ds.brandColors.typography.light}
              textAlign={['center', 'center']}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t5, ds.typeScale.t7]}
            >
              {page.heroSubheading}
            </H2>
            {page.heroButton ? (
              <ds.StyledHeaderCtaDiv>
                {isBrowser ? (
                  <PCta color={ds.brandColors.lightGreen} pr={ds.spacing.s3}>
                    {page.heroButtonLabel || 'Download Now'}
                  </PCta>
                ) : null}
                {page.storeButton ? (
                  <AppStoreButtons
                    bg={page.buttonColor}
                    focus={page.buttonFocus}
                    label={page.buttonLabel || 'Download Now'}
                  />
                ) : (
                  <AngledButton
                    bg={page.buttonColor}
                    focus={page.buttonFocus}
                    label={page.buttonLabel || `TONIT`}
                    href={page.buttonLink}
                  />
                )}
              </ds.StyledHeaderCtaDiv>
            ) : null}
          </ds.FixedWidthContainer>
        </ds.Background>
        <ds.Background background="#202020" pb={ds.spacing.s6}>
          <ds.FixedWidthContainer
            bg="white"
            py={ds.spacing.s5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              justifyContent: 'center',
            }}
          >
            <TriangleDiv display={['none', 'block', 'block']} />
            <Cta
              heading={page.introMessage}
              subheading={page.callToAction}
              introImage={page.introImage}
              screenshot={CTAscreenshot.node.childImageSharp.fixed}
              imageDescription={page.imageDescription}
            />
          </ds.FixedWidthContainer>
          {imageSectionData.map((sec, i) => {
            if (i === 0) {
              return (
                <React.Fragment key={sec.image.id}>
                  <GatsbyImageSection
                    key={i}
                    idx={i}
                    box={true}
                    url={sec.image.fluid}
                    {...sec}
                  />
                  <InfoContainerSection
                    {...page.firstInfoSection}
                    image={screenshots.shot0.node.childImageSharp.fluid}
                    pt={[ds.spacing.s5, ds.spacing.s4]}
                    pb={[ds.spacing.s5, ds.spacing.s6]}
                    index={i}
                  />
                </React.Fragment>
              )
            }
            if (i === 1) {
              return (
                <React.Fragment>
                  <GatsbyImageSection
                    key={i}
                    idx={i}
                    url={sec.image.fluid}
                    {...sec}
                  />
                </React.Fragment>
              )
            } else {
              return (
                <GatsbyImageSection
                  key={sec.image.id}
                  idx={i}
                  box={true}
                  url={sec.image.fluid}
                  {...sec}
                />
              )
            }
          })}
          {quoteSectionData.map((q, i) => {
            return (
              <GatsbyQuoteSection
                {...q}
                key={`${i}${q.largeText}`}
                url={q.image.fluid}
              />
            )
          })}

          <InfoContainerSection
            {...page.secondInfoSection}
            image={screenshots.shot1.node.childImageSharp.fluid}
            pt={[ds.spacing.s5, ds.spacing.s4]}
            pb={[ds.spacing.s5, ds.spacing.s7]}
            index={1}
          />
          <InfoContainerSection
            {...page.thirdInfoSection}
            image={screenshots.shot2.node.childImageSharp.fluid}
            pt={[ds.spacing.s5, ds.spacing.s4]}
            pb={[ds.spacing.s5, ds.spacing.s7]}
            index={2}
          />
          <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
            <QuoteSlider items={testimonialSectionData} />
          </div>
        </ds.Background>
      </Layout>
    </LocationContext.Provider>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "tonithomepageheader.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        ctaScreenshot: allFile(
          filter: { name: { regex: "/^homepagecta*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                  height
                  width
                }
              }
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^homepagescreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        sections: allFile(
          filter: { name: { regex: "/^homepageSection*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        quotes: allFile(
          filter: { name: { regex: "/^homepageQuote*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        testimonials: allFile(
          filter: { name: { regex: "/^homepageTestimonial*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allHomeJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              heroSubheading
              heroButton
              storeButton
              buttonLabel
              buttonLink
              heroButtonLabel
              buttonColor
              buttonFocus
              introImage
              imageDescription
              introMessage
              callToAction
              imageSections {
                containerColor
                urlDescription
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
              }
              quoteSections {
                containerColor
                smallText
                smallTextColor
                largeText
                largeTextColor
                flip
                textAlign
                button
                storeButton
                buttonLink
                buttonLabel
                buttonBg
                buttonFocus
                backgroundAlt
              }
              firstInfoSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonColor
                buttonFocus
                buttonLabel
                buttonLink
                triangle
                bg
                order
                textAlign
                imageExists
                imageAlt
              }
              secondInfoSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonColor
                buttonFocus
                buttonLabel
                buttonLink
                triangle
                bg
                order
                textAlign
                imageExists
                imageAlt
              }
              thirdInfoSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonColor
                buttonFocus
                buttonLabel
                buttonLink
                triangle
                bg
                order
                textAlign
                imageExists
                imageAlt
              }
              testimonials {
                containerColor
                smallText
                smallTextColor
                largeText
                largeTextColor
                flip
                textAlign
                button
                storeButton
                buttonLink
                buttonLabel
                buttonBg
                buttonFocus
                backgroundAlt
              }
              pageTitle
              pageDescription
              socialTitle
              socialDescription
              socialImage
            }
          }
        }
      }
    `}
    render={data => <Homepage data={data} {...props} />}
  />
)
