import React from "react";
import * as ds from "../uiLibrary";
import { PCta, H2 } from "../uiLibrary/Typographyv2";
import ftIcon from "../../static/images/Throttle-Table-Icon.png";
import freeIcon from "../../static/images/Free-Table-Icon.png";

export const TableComponent = ({ data }) => {
  return <ds.FixedWidthContainer bg="black" height={"auto"} pt={[ds.spacing.s5]}>
    <ds.StyledTable pt={[0, 0, "4em"]} pb={["2em", "2em", 0]} px={["2em", "2em", "7em"]}>
      <thead>
        <tr>
          <th>
            <H2 textAlign="center" fontSize={[ds.typeScale.t6, ds.typeScale.t6, ds.typeScale.t7]} color={ds.brandColors.lightGold}>{data.tableHeader}</H2>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          data.tableFeatures.map((f, i) => {
            return <React.Fragment key={`features${i}`}>
              <ds.TableRow>
                {
                  f.title.map((t, i) => {
                    return <ds.TableData key={`${i}${t}`} flex={i === 0 ? [3, 2] : [0, 1]} width={i === 0 ? ["0", "400px"] : "auto"} justifyContent={i === 0 ? "center" : "space-evenly"}>
                      <PCta key={`${t}${i}`} pt={[0, ds.spacing.s4]} pb={[0, ds.spacing.s3]} color={ds.brandColors.mediumGold} display={i === 0 ? "block" : ["none", "block"]}>
                        {t}
                      </PCta>
                    </ds.TableData>
                  })
                }
              </ds.TableRow>
              {
                f.features.map((f, i) => {
                  return <ds.TableRow
                    key={i}
                    bg={"black"}
                    borderTop={i === 0 ? `2px solid ${ds.brandColors.mediumGold}` : "none"}
                    borderBottom={`2px solid ${ds.brandColors.mediumGold}`}
                    borderLeft={`2px solid ${ds.brandColors.mediumGold}`}
                    borderRight={`2px solid ${ds.brandColors.mediumGold}`}
                  >
                    <ds.TableData key={`${i}${f.description}`} flex={2} justifyContent="flex-end" textAlign="right">
                      {f.description}
                    </ds.TableData>
                    <ds.TableData key={`basic${i}${f.description}`} justifyContent={"center"} flex={1}>
                      {
                        f.basic
                          ? <ds.TableIcon src={freeIcon} />
                          : ""
                      }
                    </ds.TableData>
                    <ds.TableData key={`fullthrottle${i}${f.description}`} justifyContent={"center"} flex={1}>
                      {
                        f.fullThrottle
                          ? <ds.TableIcon src={ftIcon} />
                          : ""
                      }</ds.TableData>
                  </ds.TableRow>
                })
              }
            </React.Fragment>
          })}
      </tbody>
    </ds.StyledTable>
  </ds.FixedWidthContainer>
}