import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'
import { display, space } from 'styled-system'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding: ${ds.spacing.s1} 0;
`

const StyledLink = styled.a`
  color: ${ds.colors.lightGray};
  text-decoration: none;
  font-family: Roboto Condensed, ${ds.sansSerif};
  transition: color 0.3s;
  font-size: ${ds.typeScale.t4};
  display: inline;
  opacity: 0.8;
  transition: color 0.2s;

  &.active,
  &:hover {
    color: ${ds.colors.white};
    opacity: 1;
  }
`

const Spacer = styled.span`
  display: inline-block;
  height: 12px;
  width: 1px;
  background-color: ${ds.colors.lightGray};
  margin: 1px 10px 0;
  opacity: 0.8;
`

const Desktop = styled.div`
  ${display};
`
const Mobile = styled.div`
  padding-top: ${ds.spacing.s4};
  ${display};
`

const LanguageSwitcher = ({ locale }) => {
  const fallbackLocale = locale || 'en'

  const style = { display: 'block' }
  return (
    <div style={style}>
      <StyledLink href="/" className={fallbackLocale === 'en' ? 'active' : ''}>
        en
      </StyledLink>
      <Spacer />
      <StyledLink
        href="/es/"
        className={fallbackLocale === 'es' ? 'active' : ''}
      >
        es
      </StyledLink>
    </div>
  )
}

export const LanguageSwitcherDesktop = props => {
  return (
    <Desktop display={['none', 'block']}>
      <ds.Background background="#202020" mx="auto" textAlign="center">
        <ds.FixedWidthRow py={0}>
          <ds.Column span={12}>
            <Wrapper>
              <LanguageSwitcher {...props} />
            </Wrapper>
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Desktop>
  )
}

export const LanguageSwitcherMobile = props => {
  return (
    <Mobile display={['block', 'none']}>
      <LanguageSwitcher {...props} />
    </Mobile>
  )
}
