import styled from 'styled-components'
import React, { useEffect } from 'react'
import * as ds from '../uiLibrary'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: visible;

    .modal-layout {
      transform: translateY(0);
    }
  }
`

const ModalLayout = styled.div`
  width: 95vw;
  max-width: 800px;
  max-height: 600px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  transition: 0.5s;
  transform: translateY(-100px);
`

const ModalWrap = styled.div``

const ModalHeaderWrap = styled.div`
  height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${ds.spacing.s4};
  background-color: ${ds.colors.darkGray};
`

const ModalContentWrap = styled.div`
  padding: ${ds.spacing.s2} ${ds.spacing.s4} ${ds.spacing.s4};
  max-height: 540px;
  overflow-x: scroll;
  box-sizing: border-box;
`
const MaterialIcon = styled.span`
  font-family: 'Material Icons';
  font-size: 22px;
  color: ${ds.brandColors.typography.lightGrey};
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`

const CloseWrap = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`

export const Modal = ({ onClose = () => null, isVisible, children }) => {
  useEffect(() => {
    const closeOnEscapeKeyDown = e => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose()
      }
    }

    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  }, [])

  return (
    <Overlay className={isVisible ? 'active' : ''} onClick={() => onClose()}>
      <ModalLayout className="modal-layout" onClick={e => e.stopPropagation()}>
        <ModalWrap>{children}</ModalWrap>
      </ModalLayout>
    </Overlay>
  )
}

Modal.Header = ({ children, onClose }) => {
  return (
    <ModalHeaderWrap>
      {children}
      {onClose ? (
        <CloseWrap>
          <MaterialIcon
            name="TONIT menu button"
            fontSize={5}
            color={ds.brandColors.typography.lightGrey}
            onClick={onClose}
          >
            close
          </MaterialIcon>
        </CloseWrap>
      ) : null}
    </ModalHeaderWrap>
  )
}

Modal.Content = ({ children }) => {
  return <ModalContentWrap>{children}</ModalContentWrap>
}
