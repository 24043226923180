import React, { useState } from 'react'
import * as ds from '../../uiLibrary'
import { Modal } from '../../uiLibrary/Modal'
import { H3, H4 } from '../../uiLibrary/Typographyv2'
import { AngledButton } from '../AngledButton'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

const Link = styled.a`
  cursor: pointer;

  .hr {
    transition: 0.3s;
  }

  &:hover {
    .hr {
      border-color: ${ds.colors.brandRed} !important;
      width: 40% !important;
    }
  }
`

export const Champion = ({ profileLink, bio, ...rest }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const openModal = e => {
    e.preventDefault()
    setModalVisible(true)
  }

  return (
    <>
      <div style={{ paddingBottom: ds.spacing.s4 }}>
        {bio ? (
          <Link onClick={openModal}>
            <Content {...rest} />
          </Link>
        ) : (
          <Content {...rest} />
        )}
        <div style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block' }}>
            <AngledButton
              label={'Connect on Tonit'}
              href={profileLink}
              bg={ds.brandColors.darkRed}
              focus={ds.brandColors.lightRed}
            />
          </div>
        </div>
      </div>
      {bio ? (
        <Modal onClose={() => setModalVisible(false)} isVisible={modalVisible}>
          <Modal.Header onClose={() => setModalVisible(false)}>
            {<ds.H3 my={0}>{rest.name}</ds.H3>}
          </Modal.Header>
          <Modal.Content>
            <ReactMarkdown>{bio}</ReactMarkdown>
          </Modal.Content>
        </Modal>
      ) : null}
    </>
  )
}

const Content = ({ image, name, city, state, divColor, bio, bioImage }) => {
  const src = image ? image.fluid.src : bioImage
  return (
    <>
      <div
        className="img"
        style={{
          width: '100%',
          backgroundImage: `url(${src || ''})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          paddingTop: '75%',
        }}
      />
      <div style={{ textAlign: 'center' }}>
        <H3
          style={{ marginTop: ds.spacing.s3, marginBottom: ds.spacing.s3 }}
          className="title"
        >
          {name}
        </H3>
        <div
          className="hr"
          style={{
            borderBottom: `3px solid ${divColor || ds.brandColors.mediumGreen}`,
            width: '30%',
            margin: '0 auto',
          }}
        />
        <H4>{`${city}, ${state}`}</H4>
        {bio ? <p>See Bio</p> : null}
      </div>
    </>
  )
}
