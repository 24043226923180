import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'
import fbIcon from '../images/fb-icon.png'
import igIcon from '../images/ig-icon.png'
import { space } from 'styled-system'

const StyledIcon = styled.img`
  height: 1.66em;
  align-items: center;
  ${space};
`
export const NavBarSocialIcons = ({ accounts }) => (
  <>
    <ds.A
      ml={4}
      href={accounts.facebookUrl}
      target="_blank"
      rel="noopener"
    >
      <StyledIcon src={fbIcon} alt="Facebook official" />
    </ds.A>
    <ds.A
      ml={4}
      href={accounts.instagramUrl}
      target="_blank"
      rel="noopener"
    >
      <StyledIcon src={igIcon} alt="Instagram official" />
    </ds.A>
  </>
)

export const FooterSocialIcons = ({ accounts }) => (
  <>
    <ds.A
      pr={4}
      href={accounts.facebookUrl}
      target="_blank"
      rel="noopener"
    >
      <StyledIcon
        style={{ opacity: 0.5 }}
        src={fbIcon}
        alt="Facebook official"
      />
    </ds.A>
    <ds.A
      pr={4}
      href={accounts.instagramUrl}
      target="_blank"
      rel="noopener"
    >
      <StyledIcon
        style={{ opacity: 0.5 }}
        src={igIcon}
        alt="Instagram official"
      />
    </ds.A>
  </>
)
