import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'
import { space } from 'styled-system'

const StyledA = styled.a`
  text-decoration: none;
`

const StyledAngledButton = styled.button`
  line-height: ${ds.lineHeight.lh2};
  text-decoration: none;
  text-align: center;
  justify-content: center;
  white-space: normal;
  font-family: Roboto Condensed, ${ds.sansSerif};
  font-size: ${ds.typeScale.t4};
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  padding: ${ds.spacing.s2} ${ds.spacing.s3};
  margin: 0;
  width: auto;
  min-width: 90px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  color: ${props => (props.color ? props.color : '#fff')};
  background: ${props => (props.bg ? props.bg : ds.brandColors.darkGreen)};
  position: relative;
  border: 0;
  margin: 0 ${ds.spacing.s2};
  ${space};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -${ds.spacing.s2};
    width: ${ds.spacing.s3};
    transform: skewX(-12deg);
    background-color: inherit;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -${ds.spacing.s2};
    width: ${ds.spacing.s3};
    transform: skewX(-12deg);
    background-color: inherit;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${props =>
      props.focus ? props.focus : ds.brandColors.lightGreen};
  }
`

const StyledDiv = styled.a`
  flex: 0 1 auto;
  display: flex;
  cursor: pointer;
  z-index: 1;
`

export const AngledButton = props => {
  return (
    <StyledA
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      name={props.label || `TONIT ${props.href}`}
      {...(props.anchorProps || {})}
    >
      <StyledAngledButton bg={props.bg} focus={props.focus} color={props.color}>
        {props.label}
      </StyledAngledButton>
    </StyledA>
  )
}

export const AngledSubmitButton = props => {
  return (
    <StyledDiv onClick={props.onClick}>
      <StyledAngledButton bg={props.bg} focus={props.focus} color={props.color}>
        {props.label}
      </StyledAngledButton>
    </StyledDiv>
  )
}

export const AngledCTAButton = props => {
  return (
    <StyledAngledButton bg={props.bg} focus={props.focus} color={props.color}>
      {props.label}
    </StyledAngledButton>
  )
}
