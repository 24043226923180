import React from 'react'
import styled from 'styled-components'
import TonitLogo from '../../static/images/Tonit-Logo-White.png'
import facebookIcon from '../../static/images/Facebook-Icon.png'
import instagramIcon from '../../static/images/Instagram-icon.png'
import * as ds from '../uiLibrary'
import { SubscribeForm } from './SubscribeForm'
import { AngledButton, AppStoreButtons } from '../components'
import { width, space, textAlign } from 'styled-system'
import { PLarge } from '../uiLibrary/Typographyv2'

const StyledCopyright = styled.p`
  font-weight: 200;
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.5);
  ${space};
  ${textAlign};
`

const CenteredImage = styled.img`
  ${width};
  padding: ${ds.spacing.s2};
`

const SocialIcons = styled.img`
  margin-right: ${ds.spacing.s2};
  margin-left: ${ds.spacing.s2};
  ${width};
`

const CopyrightLink = styled.span`
  display: inline-block;
  padding: 0 ${ds.spacing.s2};
`

export const Footer = ({ data, ...props }) => {
  const footer = data

  return (
    <ds.Background background="#202020" mx="auto" textAlign="center">
      <ds.FixedWidthRow py={0}>
        <ds.Column
          vc
          span={12}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <PLarge color={'#e0e0e0'} width={['80%', '70%', '65%']}>
            {footer.newsletterBody}
          </PLarge>
          <SubscribeForm style={{ width: '65%' }} data={data.subscribe} />
        </ds.Column>
      </ds.FixedWidthRow>
      <ds.FixedWidthRow pt={ds.spacing.s4} pb={ds.spacing.s5}>
        <ds.Column vc alignItems="center" span={12} justifySelf={['center']}>
          {footer.supportLinks.map((link, i) => {
            return (
              <React.Fragment key={`${link.text}${i}`}>
                <PLarge color="#e0e0e0">{link.text}</PLarge>
                <AngledButton
                  href={link.link}
                  bg="#3F5D73"
                  focus="#264D6C"
                  label={link.buttonLabel}
                />
                <div style={{ paddingTop: ds.spacing.s3 }}>
                  <AppStoreButtons
                    label="Download Now"
                    bg={ds.brandColors.darkRed}
                    focus={ds.brandColors.lightRed}
                  />
                </div>
              </React.Fragment>
            )
          })}
        </ds.Column>
      </ds.FixedWidthRow>
      <ds.FixedWidthRow py={[ds.spacing.s3]}>
        <ds.Column span={6} justifySelf={['center', 'right']}>
          <CenteredImage
            width={['200px', '200px', '250px']}
            src={TonitLogo}
            alt={'Tonit Motorcycle Logo'}
          />
        </ds.Column>
        <ds.Column span={6} justifySelf={['center', 'left']}>
          <a href={footer.social.facebookUrl} name="Tonit facebook link">
            <SocialIcons
              width={['50px', '70px', '80px']}
              src={facebookIcon}
              alt={'TONIT Facebook'}
            />
          </a>
          <a href={footer.social.instagramUrl} name="Tonit instagram link">
            <SocialIcons
              width={['50px', '70px', '80px']}
              src={instagramIcon}
              alt={'TONIT Instagram'}
            />
          </a>
        </ds.Column>
      </ds.FixedWidthRow>
      <StyledCopyright mt={[4, 4, 5]} mb={10}>
        {footer.termLinks.map((link, i) => {
          if (!link.link) {
            return <CopyrightLink>{link.text}</CopyrightLink>
          }

          return (
            <CopyrightLink>
              <ds.A
                pb={0}
                color={ds.colors.typography.lightTertiary}
                href={link.link}
                target="_blank"
              >
                {link.text}
              </ds.A>
            </CopyrightLink>
          )
        })}
      </StyledCopyright>
    </ds.Background>
  )
}
