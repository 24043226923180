import React from 'react';
import { AngledButton, AppStoreButtons } from "../components";
import { GatsbySectionHero } from "../components/heroes";
import * as ds from "../uiLibrary";
import { H2 } from "../uiLibrary/Typographyv2";

export const GatsbyQuoteSection = ({
  containerColor,
  smallText,
  smallTextColor,
  largeText,
  largeTextColor,
  textAlign,
  flip,
  button,
  storeButton,
  buttonLink,
  buttonLabel,
  buttonBg,
  buttonFocus,
  url,
  urlDescription,
}) => {
  return <ds.FixedWidthContainer bg={containerColor} >
    <GatsbySectionHero fluid={url} alt={urlDescription} style={{ paddingTop: "10rem", alignItems: "baseline", display: "flex" }} height={["400px", "600px"]}>
      <ds.FixedWidthRow flexDirection="column" py={0}>
        <ds.Column span={12} alignItems="center" textAlign={textAlign} order={flip ? 2 : 1}>
          <H2 fontSize={[ds.typeScale.t6, ds.typeScale.t8]} color={smallTextColor} my={0}>{smallText}</H2>
        </ds.Column>
        <ds.Column span={12} alignItems="center" textAlign={textAlign} order={flip ? 1 : 2}>
          <H2 fontSize={[ds.typeScale.t7, "6.99rem"]} color={largeTextColor} my={0}>{largeText}</H2>
        </ds.Column>
        <ds.Column span={12} alignItems="center" textAlign={textAlign} order={3}>
          {
            button
              ? <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                {
                  storeButton
                    ? <AppStoreButtons bg={buttonBg} focus={buttonFocus} label={buttonLabel || "Download Now"} />
                    : <AngledButton bg={buttonBg} focus={buttonFocus} label={buttonLabel || `TONIT`} href={buttonLink} />
                }
              </div>
              : null
          }
        </ds.Column>
      </ds.FixedWidthRow>
    </GatsbySectionHero>
  </ds.FixedWidthContainer>
}