import React from 'react'
import * as ds from '../uiLibrary'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

const StyledColumn = styled(ds.Column)`
  padding: 0;
  margin: 0;
`
const StyledP = styled(ds.P)`
  padding-bottom: ${ds.spacing.s3};
  margin-top: 0;
  color: ${ds.colors.typography.darkSecondary};
  font-size: ${ds.fontSizes[3]};

  &:not(:last-of-type) { 
    padding-bottom: 0; 
    margin-bottom: 0;
  }
`
const StyledH4 = styled(ds.H4)`
  color: ${ds.colors.typography.darkPrimary};
  margin-top: 0;
`

export const FactSheet = ({ facts, ...props }) => (
  <>
    {facts.map((fact) => (
      <ds.FixedWidthRow key={fact.title} py={0}>
        <StyledColumn span={3}>
          <StyledH4>{fact.title}</StyledH4>
        </StyledColumn>
        <StyledColumn span={9}>
          <ReactMarkdown
            source={fact.details}
            renderers={{
              paragraph: props => <StyledP {...props} />,
              link: props => <ds.A color={ds.colors.brandRed} href={props.href} {...props} />
            }}
          />
        </StyledColumn>
      </ds.FixedWidthRow>
    ))}
  </>
)