import React from 'react'
import * as ds from '../uiLibrary'

export const CareersPerk = props =>
  <ds.Row>
    <ds.Column textAlign="center" span={3}>
      <ds.Icon src={props.iconSrc} />
    </ds.Column>
    <ds.Column span={9}>
      <ds.H4 color={ds.colors.typography.darkPrimary} style={{marginTop: 0}}>
        { props.title }
      </ds.H4>
      <ds.P color={ds.colors.typography.darkSecondary} fontSize={ds.typeScale.t4}>
      { props.children }
      </ds.P>
    </ds.Column>
  </ds.Row>