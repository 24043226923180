import React from "react";
import * as ds from "../uiLibrary";
import { PLarge, H3 } from "../uiLibrary/Typographyv2";

export const IconSection = props => {
  return <ds.FixedWidthRow py={ds.spacing.s3}>
    <ds.Column span={4} display="flex" justifyContet="center" alignItems="center">
      <ds.CenteredImage key={props.icon} mw={"50%"} src={props.icon} alt={props.iconAlt} />
    </ds.Column>
    <ds.Column span={8} textAlign={["center", "left"]}>
      <H3>
        {props.title}
      </H3>
      <PLarge fontSize={[ds.typeScale.t4, ds.typeScale.t5]}>
        {props.description}
      </PLarge>
    </ds.Column>
  </ds.FixedWidthRow>
}