import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'

const HoverTrigger = styled.div`
  transition: .3s all;
  padding: 32px;
  text-align: center;
  &:first-child:hover {
    transform-origin: 50% 50%;
    transition: .3s all;
    transform: rotate(-33deg);
  }
`
const AnimatedBike = props =>
  <ds.Div position='relative'>
    <HoverTrigger>
      {props.children}
    </HoverTrigger>
  </ds.Div>

export const BikeTriptic = ({ icons, ...props }) =>
  <ds.Div {...props}>
    <ds.Flex justifyContent='space-between'>
      {icons.map((icon, idx) => (
        <AnimatedBike key={idx}>
          <ds.RetinaScaledImage src={icon.icon} alt={icon.description} />
        </AnimatedBike>
      ))}
    </ds.Flex>
  </ds.Div>