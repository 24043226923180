import React, { useState } from 'react'
import styled from 'styled-components';
import * as ds from '../uiLibrary';
import { space } from 'styled-system';
import Axios from 'axios';
import { withStyleFunctions } from '../utils';
import { AngledButton } from './AngledButton';

const InputLabel = styled(ds.P)`
  font-family: Overpass, ${ds.sansSerif};
  margin-bottom: 0;
  color: ${ds.colors.typography.lightSecondary};
`

const StyledSelect = styled.select`
  background-color: ${ds.brandColors.darkGreen};
  border-color: ${ds.brandColors.darkGreen};
  color: ${ds.colors.softWhite};
  padding: 0.5em 1em;
  font-weight: normal;
  font-size: 1rem;
  margin-top: 1em;
  line-height: 1;
`

const StyledTextArea = styled.textarea`
  background: rgba(255,255,255,0.1);
  color: ${ props => props.color ? props.color : ds.colors.softWhite};
  border-width: ${ props => props.borderWidth ? props.borderWidth : 0};
  border-bottom: ${ props => props.borderBottom ? props.borderBottom : 0};
  border-radius: ${ props => props.borderRadius ? props.borderRadius : "2px"};
  padding: 0.25em 0.5em;
  margin-top: 0.5em;
  font-size: 1.5em;
  width: 95%;
  ${space}
  &::-webkit-input-placeholder {
    color: ${ props => props.placeholderColor ? props.placeholderColor : ds.colors.typography.darkTertiary};
    font-size: ${ds.typeScale.t4}
}
`
const initialState = {
  username: '',
  email: '',
  firstname: '',
  lastname: '',
  whyInterested: '',
  howInvolved: '',
  currentClub: '',
  greatFit: '',
  describeTonit: '',
  tshirtSize: '',
  address: '',
  city: '',
  zip: '',
}
const Apply = props => {
  const [fields, setFields] = useState({
    ...initialState,
    success: '',
    error: '',
  })

  const data = {
    submittedAt: Date.now(),
    fields: [
      { name: "tonit_username", value: fields.username },
      { name: "email", value: fields.email },
      { name: "firstname", value: fields.firstname },
      { name: "lastname", value: fields.lastname },
      { name: "why_are_you_interested_in_the_community_champion_program_", value: fields.whyInterested },
      { name: "how_would_you_like_to_get_involved_", value: fields.howInvolved },
      { name: "are_you_currently_in_a_motorcycle_club_or_community_", value: fields.currentClub },
      { name: "why_do_you_believe_you_would_be_a_great_fit_for_the_program_", value: fields.greatFit },
      { name: "tell_us_about_how_you_describe_tonit_to_your_friends_colleagues_community", value: fields.describeTonit },
      { name: "too_receive_your_tonit_swag_what_s_your_t_shirt_size_", value: fields.tshirtSize },
      { name: "address", value: fields.address },
      { name: "city", value: fields.city },
      { name: "zip", value: fields.zip },
    ],
    context: {
      pageUri: "www.tonit.com/champions-apply",
      pageName: "Champions apply"
    },
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await Axios({
        method: "POST",
        headers: { "Content-Type": "Application/JSON" },
        url: "https://api.hsforms.com/submissions/v3/integration/submit/5578647/ba51c0b5-a0fd-471a-8b5e-e1833f893587",
        data,
      }).then(
        setFields({ ...initialState, success: "Thanks for submitting the form, we'll be in touch soon. Ride safe!", error: "" })
      );
    } catch (e) {
      const errorType = e.response.data.errors[0].errorType;
      const errorMessage = e.response.data.errors[0].message;

      if (errorType === "INVALID_EMAIL") {
        setFields({ ...fields, error: "Email address is not valid" })
      } else if (errorMessage === "Error in 'fields.too_receive_your_tonit_swag_what_s_your_t_shirt_size_'. Required field 'too_receive_your_tonit_swag_what_s_your_t_shirt_size_' is missing") {
        setFields({ ...fields, error: "Please select your t-shirt size" })
      } else {
        setFields({ ...fields, error: "Something went wrong, please try again later or contact support." });
      }
      return e;
    }
  }

  const handleInputChange = event => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    })
  }

  return <form id="apply-form" {...props} onSubmit={handleSubmit}>
    <ds.Row mr={ds.spacing.s3}>
      <ds.Column span={6}>
        <InputLabel>TONIT username</InputLabel>
        <ds.StyledInput bg={"rgba(255,255,255,0.1)"} required name="username" type="text" value={fields.username} onChange={handleInputChange} />
      </ds.Column>
      <ds.Column span={6}>
        <InputLabel>Email address</InputLabel>
        <ds.StyledInput bg={"rgba(255,255,255,0.1)"} required name="email" type="text" value={fields.email} onChange={handleInputChange} />
      </ds.Column>
    </ds.Row>
    <ds.Row mr={ds.spacing.s3}>
      <ds.Column span={6}> <InputLabel>First name </InputLabel>
        <ds.StyledInput bg={"rgba(255,255,255,0.1)"} required name="firstname" type="text" value={fields.firstname} onChange={handleInputChange} />
      </ds.Column>
      <ds.Column span={6}>
        <InputLabel >Last name</InputLabel>
        <ds.StyledInput bg={"rgba(255,255,255,0.1)"} required name="lastname" type="text" value={fields.lastname} onChange={handleInputChange} />
      </ds.Column>
    </ds.Row>
    <InputLabel mt={ds.spacing.s4}>Why are you interested in the Community Champion program?</InputLabel>
    <StyledTextArea required name="whyInterested" type="text" value={fields.whyInterested} onChange={handleInputChange} />
    <InputLabel mt={ds.spacing.s4}>How would you like to get involved?</InputLabel>
    <StyledTextArea required name="howInvolved" type="text" value={fields.howInvolved} onChange={handleInputChange} />

    <InputLabel mt={ds.spacing.s4}>Are you currently in a motorcycle club or community?</InputLabel>
    <StyledTextArea required name="currentClub" type="text" value={fields.currentClub} onChange={handleInputChange} />

    <InputLabel mt={ds.spacing.s4}>Why do you believe you would be a great fit for the program?</InputLabel>
    <StyledTextArea required name="greatFit" type="text" value={fields.greatFit} onChange={handleInputChange} />

    <InputLabel mt={ds.spacing.s4}>Tell us about how you describe TONIT to your friends/colleagues/community</InputLabel>
    <StyledTextArea required name="describeTonit" type="text" value={fields.describeTonit} onChange={handleInputChange} />

    <InputLabel mt={ds.spacing.s4}>To receive TONIT swag in the future - what's your T-shirt size?</InputLabel>
    <StyledSelect name="tshirtSize" value={fields.tshirtSize} onChange={handleInputChange}>
      <option value="select">Select Size</option>
      <option value="XS">XS</option>
      <option value="S">S</option>
      <option value="M">M</option>
      <option value="L">L</option>
      <option value="XL">XL</option>
      <option value="2XL">2XL</option>
    </StyledSelect>

    <InputLabel mt={ds.spacing.s4}>Street Address</InputLabel>
    <ds.StyledInput bg={"rgba(255,255,255,0.1)"} required name="address" type="text" value={fields.address} onChange={handleInputChange} />

    <InputLabel mt={ds.spacing.s4}>City, State</InputLabel>
    <ds.StyledInput bg={"rgba(255,255,255,0.1)"} required name="city" type="text" value={fields.city} onChange={handleInputChange} />

    <InputLabel mt={ds.spacing.s4}>ZIP/ Postal code</InputLabel>
    <ds.StyledInput bg={"rgba(255,255,255,0.1)"} required name="zip" type="text" value={fields.zip} onChange={handleInputChange} />
    <div style={{ paddingTop: ds.spacing.s4 }}>
      <AngledButton type="submit" value="submit" name="submit" label={"Submit"} />
    </div>
    {
      fields.success
        ? <div className="thankyou_message">
          <ds.P style={{ color: "white" }}>{fields.success} </ds.P>
        </div>
        : null
    }
    {
      fields.error
        ? <div className="thankyou_message">
          <ds.P style={{ color: "red" }}>{fields.error}</ds.P>
        </div>
        : null
    }
  </form>
};

export const ChampionsForm = withStyleFunctions(Apply)
