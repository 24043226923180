import styled from 'styled-components'
import { space, display } from 'styled-system'

export const HeroBackground = styled.div`
  position: relative;
  background-color: #aaaaaa;
  background: url(${props => props.url});
  background-size: cover;
  background-position: ${props => props.bp ? props.bp : "center"};
  z-index: 0;
  ${space};
  ${display};
`