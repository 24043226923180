import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { display, fontSize, color, space, opacity } from 'styled-system'
import * as ds from '../uiLibrary'
import { LanguageSwitcherMobile } from './LanguageSwitcher'

const Menu = styled.div`
  height: 100vh;
  width: calc(100% - 64px);
  background-color: #202020;
  display: flex;
  flex-direction: column;
  ${space};
`

const StyledLink = styled.a`
  color: ${ds.colors.softWhite};
  text-decoration: none;
  font-family: Roboto Condensed, ${ds.sansSerif};
  transition: color 0.3s;
  font-size: ${ds.typeScale.t4};
  ${fontSize};
  ${space};
`

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  ${color};
  ${opacity};
  ${display};
`

const GlobalStyles = createGlobalStyle`
  body {
    overflow: ${props => (props.isOpen ? `hidden` : `visible`)};
  }
`

export class Drawer extends React.Component {
  state = {
    isOpen: false,
  }
  render() {
    return (
      <>
        <StyledOverlay
          bg={'#000'}
          opacity={this.state.isOpen ? '0.7' : '0'}
          display={this.state.isOpen ? 'flex' : 'none'}
        />
        <StyledDrawer {...this.props} isOpen={this.state.isOpen}>
          <StyledButton
            name="TONIT Navigation panel"
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          >
            {this.state.isOpen ? (
              <MaterialIcon
                name="TONIT menu button"
                fontSize={5}
                color={ds.brandColors.typography.lightGrey}
              >
                close
              </MaterialIcon>
            ) : (
              <MaterialIcon
                name="TONIT menu button"
                fontSize={5}
                color={ds.colors.lightGray}
              >
                menu
              </MaterialIcon>
            )}
          </StyledButton>
          <Menu pt={ds.spacing.s4} pl={ds.spacing.s4}>
            <StyledLink py={3} fontSize={4} href="/">
              HOME
            </StyledLink>
            <StyledLink py={3} fontSize={4} href="/about">
              ABOUT
            </StyledLink>
            <StyledLink py={3} fontSize={4} href="/community">
              COMMUNITY
            </StyledLink>
            <StyledLink py={3} fontSize={4} href="/full-throttle">
              SUBSCRIBE
            </StyledLink>
            <StyledLink py={3} fontSize={4} href="https://shop.tonit.com/">
              SHOP
            </StyledLink>
            <StyledLink py={3} fontSize={4} href="/faq">
              SUPPORT
            </StyledLink>
            {/* <StyledLink py={3} fontSize={4} href="https://ztk5xlqn2xscl1ir-40452456615.shopifypreview.com">SHOP</StyledLink> */}
            <LanguageSwitcherMobile locale={this.propslocale} />
          </Menu>
          <GlobalStyles isOpen={this.state.isOpen} />
        </StyledDrawer>
      </>
    )
  }
}

export const StyledDrawer = styled.div`
  ${display};
  ${color};
  transform: ${props =>
    props.isOpen
      ? `translate3d(0, 0, 0)`
      : `translate3d(calc(101% - 64px), 0, 0)`};
  transition: 0.3s all;
  top: 0;
  position: fixed;
  overflow-x: hidden;
  right: 0;
  width: 100%;
`
export const MaterialIcon = styled.span`
  font-family: 'Material Icons';
  ${fontSize};
  ${color};
`
export const StyledButton = styled.button`
  height: 64px;
  width: 64px;
  background-color: rgba(18, 18, 24, 0);
  border-width: 0;
  border-radius: 5px;
  z-index: 1;
  &:focus {
    outline: none;
  }
`
