import React from "react";
import * as ds from "../uiLibrary";
import { H3, H4, LineItem } from "../uiLibrary/Typographyv2";
import { TriangleDiv } from "../components";


export const FixedWhiteContainer = ({
  title,
  header,
  body,
  bullets,
  link,
  py,
  index,
  left
}) => {
  return <ds.FixedWidthContainer
    bg="white"
    py={py ? py : 0}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }} >
    {
      index === 0
        ? <TriangleDiv display={["none", "block", "block"]} />
        : null
    }
    {
      title
        ? <H3
          fontSize={[ds.typeScale.t5, ds.typeScale.t6, ds.typeScale.t7]}
          width={["80%", "70%"]}
          textAlign={left ? "left" : "center"}
          alignSelf="center"
          style={{ textTransform: "uppercase" }}
        >
          {title}
        </H3>
        : null
    }
    {
      header
        ? <H3
          fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t6]}
          fontWeight="700"
          width={["80%", "70%"]}
          textAlign={left ? "left" : "center"}
          alignSelf="center"
          color={ds.brandColors.typography.dark}>
          {header}
        </H3>
        : null
    }
    {
      body
        ? <H4
          fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t5]}
          width={["80%", "70%"]}
          textAlign={left ? "left" : "center"}
          alignSelf="center"
          color={ds.brandColors.typography.dark}>
          {body}
        </H4>
        : null
    }
    {
      link && link.exists
        ? <H4
          fontSize={[ds.typeScale.t4, ds.typeScale.t5, ds.typeScale.t5]}
          width={["80%", "70%"]}
          textAlign={left ? "left" : "center"}
          alignSelf="center"
          color={ds.brandColors.typography.dark}>
          <a href={link.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: ds.brandColors.darkBlue }}>
            {link.linkText}
          </a>
        </H4>
        : null
    }
    {
      bullets
        ? <ul style={{ display: "flex", flexDirection: "column" }}>
          {bullets.map((line, i) => {
            return <LineItem
              key={i}
              textAlign="left"
              width={["80%", "70%"]}
              alignSelf="center"
              fontSize={[ds.typeScale.t3, ds.typeScale.t4, ds.typeScale.t5]}
              color="#414141"
            >
              {line}
            </LineItem>
          })} </ul>
        : null
    }
  </ds.FixedWidthContainer>
}
